/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */
(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var fwd = {
    // All pages
    common: {
      init: function() {
        // Main Slider
        // ====================================

        if (
          !(
            $("body").hasClass("work") ||
            $("body").hasClass("single-case-study")
          )
        ) {
          $(".js-site-slider").fullpage({
            sectionSelector: ".js-section",
            verticalCentered: false,
            responsiveWidth: 900,
            scrollingSpeed: 700,
            scrollOverflow: true,

            afterLoad: function() {
              // BackgroundCheck.refresh();
            }
          });
        }

        //Section anchors
        $(".c-section-container")
          .find(".c-section__anchor-inner")
          .each(function() {
            //The text for each section anchor is stored in the next sections 'data-anchor-link'
            var title = $(this)
              .closest("section")
              .next("section")
              .data("anchor-link");
            $(this).text(title);
          });

        $(document).on("click", ".js-section-anchor", function(event) {
          event.preventDefault();
          $.fn.fullpage.moveSectionDown();
        });

        // Content Slider
        // ====================================

        $(".js-content-slider").each(function(index, element) {
          $this = $(this);

          var swiper = new Swiper(this, {
            speed: 500,
            loop: true,
            slidesPerView: 3,
            spaceBetween: 30,
            breakpoints: {
              580: {
                slidesPerView: 1
              },
              800: {
                slidesPerView: 2,
                spaceBetween: 10
              }
            },
            navigation: {
              nextEl: $this.parent().find(".js-content-slider__arrow--next")[0],
              prevEl: $this.parent().find(".js-content-slider__arrow--prev")[0]
            }
          });
        });

        // Team Slider
        // ====================================

        var intro = new Swiper(".js-intro", {
          effect: "fade",
          fadeEffect: {
            crossFade: false
          },
          autoplay: {
            delay: 5000
            // stopOnLastSlide: true
          }
        });

        var team = new Swiper(".js-team-slider", {
          speed: 500,
          loop: true,
          slidesPerView: 4,
          spaceBetween: 20,
          breakpoints: {
            580: {
              slidesPerView: 1
            },
            800: {
              slidesPerView: 2,
              spaceBetween: 10
            },
            1050: {
              slidesPerView: 3
            }
          },
          navigation: {
            nextEl: $(".js-team-slider")
              .parent()
              .find(".js-team-slider__arrow--next"),
            prevEl: $(".js-team-slider")
              .parent()
              .find(".js-team-slider__arrow--prev")
          }
        });

        // Clients Slider
        // ====================================

        var clients = new Swiper(".js-clients", {
          slidesPerView: 4,
          slidesPerColumn: 3,
          spaceBetween: 55,
          breakpoints: {
            400: {
              slidesPerView: 1,
              slidesPerColumn: 4,
              spaceBetween: 20
            },
            580: {
              slidesPerView: 2,
              slidesPerColumn: 2,
              spaceBetween: 50
            },
            800: {
              slidesPerView: 3,
              slidesPerColumn: 3,
              spaceBetween: 40
            }
          },
          navigation: {
            nextEl: $(".js-clients")
              .parent()
              .find(".js-clients__arrow--next"),
            prevEl: $(".js-clients")
              .parent()
              .find(".js-clients__arrow--prev")
          }
        });

        // Responsive Background Images
        // ====================================

        function getBp() {
          var bp = window
            .getComputedStyle(document.body, ":after")
            .getPropertyValue("content")
            // Regexp for removing quotes added by various browsers
            .replace(/^["']|["']$/g, "");

          return bp;
        }

        function setBg($el, prop) {
          if ($el.attr("data-bg-" + prop)) {
            $el.css(
              "background-image",
              "url(" + $el.attr("data-bg-" + prop) + ")"
            );
          }
        }

        function ResponsiveBg($el) {
          $(window).smartresize(changeBg);
          changeBg();

          function changeBg() {
            if (getBp() === "xs") {
              setBg($el, "xs");
            } else if (getBp() === "sm") {
              setBg($el, "sm");
            } else if (getBp() === "lg") {
              setBg($el, "lg");
            }
          }
        }

        $(".js-section-bg").each(function(index, element) {
          ResponsiveBg($(this));
        });

        // Site Nav
        // ====================================

        var isTriggerActive = false;
        var $siteNav = $(".c-site-nav");
        var $siteNavTrigger = $(".js-nav-trigger");

        function openNav() {
          isTriggerActive = true;
          $siteNav.addClass("is-open");
          $siteNavTrigger.addClass("is-active");
          $("html").addClass("is-locked");
          $("body").addClass("is-nav-open");

          disableBodyScroll(true, ".c-site-nav__inner");
          if ($("html").hasClass("fp-enabled"))
            $.fn.fullpage.setAllowScrolling(false);
        }

        function closeNav() {
          isTriggerActive = false;
          $siteNav.removeClass("is-open");
          $siteNavTrigger.removeClass("is-active");
          $("html").removeClass("is-locked");
          $("body").removeClass("is-nav-open");

          disableBodyScroll(false, ".c-site-nav__inner");
          if ($("html").hasClass("fp-enabled"))
            $.fn.fullpage.setAllowScrolling(true);
        }

        $siteNavTrigger.on("click", function(event) {
          event.preventDefault();

          if (!isTriggerActive) {
            openNav();
          } else {
            closeNav();
          }
        });

        $(document).on("click", function(event) {
          if (!$(event.target).closest($siteNav).length) {
            closeNav();
          }
        });

        // Sticky blog sidebar
        var articlePadding = $(".js-article").css("padding-top");

        function initSticky() {
          if (getBp() !== "lg" && getBp() !== "xl") {
            $(".js-article-sidebar").trigger("sticky_kit:detach");
          } else {
            $(".js-article-sidebar").stick_in_parent({
              parent: $(".c-article"),
              offset_top: parseInt(articlePadding, 10)
            });
          }
        }

        initSticky();
        $(window).smartresize(initSticky);
      }
    },
    // Home page
    home: {
      init: function() {}
    },
    // Work page
    work: {
      init: function() {
        var portfolioSpeed = 400;

        var portfolio = new Swiper(".js-portfolio", {
          speed: portfolioSpeed,
          slidesPerView: 1,
          loopedSlides: $(".js-portfolio .swiper-slide").length,
          loopAdditionalSlides: $(".js-portfolio .swiper-slide").length,
          hashNavigation: true,
          navigation: {
            nextEl: $(".js-portfolio")
              .parent()
              .find(".js-portfolio-next"),
            prevEl: $(".js-portfolio")
              .parent()
              .find(".js-portfolio-prev")
          }
        });
        var portfolioThumbs = new Swiper(".js-portfolio-thumbs", {
          speed: portfolioSpeed,
          spaceBetween: 10,
          slidesPerView: 7,
          slideToClickedSlide: true,
          hashNavigation: true,
          centeredSlides: true,
          breakpoints: {
            400: {
              centeredSlides: false,
              slidesPerView: 2
            },
            650: {
              slidesPerView: 3
            },
            1000: {
              slidesPerView: 5
            }
          },
          navigation: {
            nextEl: $(".js-portfolio-thumbs")
              .parent()
              .find(".js-portfolio-thumbs__arrow--next"),
            prevEl: $(".js-portfolio-thumbs")
              .parent()
              .find(".js-portfolio-thumbs__arrow--prev")
          }
        });

        portfolioThumbs.controller.control = portfolio;
        portfolio.controller.control = portfolioThumbs;
      }
    },
    // Work page
    single_case_study: {
      init: function() {
        var portfolioSpeed = 400;
        var startSlide;

        var caseStudy = $(".js-portfolio")
          .parents("section")
          .data("case-study");
        var startSlide = 0;

        //get the starting slide index for this case study's slug
        $(".c-portfolio-slider .swiper-slide").each(function(i) {
          var cStudy = $(this).data("hash");
          if (cStudy == caseStudy) startSlide = i;
        });

        var portfolio = new Swiper(".js-portfolio", {
          initialSlide: startSlide,
          speed: portfolioSpeed,
          slidesPerView: 1,
          loopedSlides: $(".js-portfolio .swiper-slide").length,
          loopAdditionalSlides: $(".js-portfolio .swiper-slide").length,
          hashNavigation: false
        });
        var portfolioThumbs = new Swiper(".js-portfolio-thumbs", {
          initialSlide: startSlide,
          speed: portfolioSpeed,
          spaceBetween: 10,
          slidesPerView: 7,
          slideToClickedSlide: true,
          hashNavigation: false,
          centeredSlides: true,
          breakpoints: {
            400: {
              centeredSlides: false,
              slidesPerView: 2
            },
            650: {
              slidesPerView: 3
            },
            1000: {
              slidesPerView: 5
            }
          },
          navigation: {
            nextEl: $(".js-portfolio-thumbs")
              .parent()
              .find(".js-portfolio-thumbs__arrow--next"),
            prevEl: $(".js-portfolio-thumbs")
              .parent()
              .find(".js-portfolio-thumbs__arrow--prev")
          }
        });

        portfolioThumbs.controller.control = portfolio;
        portfolio.controller.control = portfolioThumbs;
      }
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var namespace = fwd;
      funcname = funcname === undefined ? "init" : funcname;
      if (
        func !== "" &&
        namespace[func] &&
        typeof namespace[func][funcname] === "function"
      ) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      UTIL.fire("common");

      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function(
        i,
        classnm
      ) {
        UTIL.fire(classnm);
      });
    }
  };

  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
